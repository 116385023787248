html, body, p, h1, h2, h3, h4, h5, table {
    font-family: 'Roboto', sans-serif;
}

.ui.inverted.dark-blue.menu, .ui.inverted.menu .dark-blue.active.item {
    background: #161e2c;
    background-image: -webkit-gradient(linear,10% 20%,60% 80%,from(#161e2c),to(#27467b));
    background: -moz-linear-gradient(right, #161e2c, #27467b);
    background: linear-gradient(to right, #161e2c, #27467b);
    border-radius: 0;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

#topNav {
    display: flex;
    max-height: 40px;
    margin: 0;
}

#topNav img {
    position: relative;
    top: -2px;
    height: 50px;
    margin-left: 15px;
}

#regattasTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

h1.title {
    font-weight: 200;
}

.dark-blue h4 {
    color: #fdfdfd;
    padding-top: 11px;
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: normal;
}

.clubLabel {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;    
}

.clubLabel.big {
    justify-content: center;   
}

.clubLabel .name {
    padding-left:10px;
}

.crewRow {
    padding-left: 13px;
    color: #666;
    margin-top: 5px;

}

.eventStateLabel {
    text-transform: uppercase !important;
    text-align: center;
}

.eventListContainer {
    margin-top:30px;
}

.eventListContainer h4 {
    margin: 0;
    font-weight: normal;
}

.laneNumber {
    text-transform: uppercase;
    font-size: 0.9em;
    text-align: center;
}

.eventStateLabel-List {
    float:right;
}

.eventStartTime-List {
    font-weight: 800;
    margin-bottom: 5px;
    font-size: 1.1em;
    margin-top:5px;
}

#entriesContainer {
    padding-bottom:20px;
    margin-top:10px;
}

#entriesContainer .entry {
    border-right: 1px dashed #CCC;
    display: flex;
    flex-direction: column;
}

#entriesContainer .entry:last-child {
    border:0;
}

#entriesContainer .entry .crew {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    margin-bottom: 2rem;
    flex: 2;
}

#entriesContainer .entry .crew div {
    margin-bottom: 5px;
}

#entriesContainer .entry .position {
    margin-top:auto;
}

#entriesContainer .entry .time {
    margin-bottom: 15px;
    padding-left: 2rem;
}

.timeValue {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2em;
}

.refreshCheckBox {
    float: right;
}
